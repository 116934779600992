// ##################################################
// 0.0 Icons
// ##################################################

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--color-black);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&-chevron-down,
	&-chevron-up {
		width: 9px;
		height: 6px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-up {
		&:before {
			transform: scale(-1);
		}
	}

	// ##################################################

	&-camel {
		width: 74px;
		height: 69px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			width: 60px;
			height: 49px;
		}

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_camel}');
			mask-image: url('data:image/svg+xml,#{$icon_camel}');
		}
	}

	// ##################################################

	&-play {
		width: 28px;
		height: 32px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_play}');
			mask-image: url('data:image/svg+xml,#{$icon_play}');
		}
	}

	// ##################################################

	&-swipe {
		width: 85px;
		height: 88px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_swipe}');
			mask-image: url('data:image/svg+xml,#{$icon_swipe}');
		}
	}
}

//**********************************************************
// Card Slider
//**********************************************************

@import '../../../scss/import';

.card-slider {
	max-width: 575px;
	width: 100%;
	margin: 10px auto 0;
	text-align: center;
	user-select: none;
	color: var(--color-sky-blue-2);
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		flex: auto;
	}

	// ##################################################

	&__swipe-label {
		// Tablet
		@media #{$breakpoint-sm-md-only} {
			font-size: 18px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 13px;
		}

		// ##################################################

		.icon {
			margin: 14px auto 0;
			transform: translate(20px);
			animation: animate-swipe-hand 1s alternate infinite ease-in;

			@media (max-height: 800px) {
				width: 60px;
				height: 60px;
			}

			// Tablet
			@media #{$breakpoint-sm-md-only} {
				width: 65px;
				height: 65px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				width: 48px;
				height: 50px;
				margin-top: 12px;
			}

			&::before {
				background-color: currentColor;
			}
		}
	}

	// ##################################################

	&__inner {
		max-width: 332px;
		width: 100%;
		margin: 0 auto;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			max-width: 285px;
			flex: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	// ##################################################

	.swiper {
		overflow: visible;
	}

	// ##################################################

	.swiper-slide {
		display: flex;
		flex-direction: column;
		height: auto;
	}

	// ##################################################

	&__slide {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 100%;

		// ##################################################

		img {
			width: 100%;
			height: auto;
			object-fit: contain;
			max-height: 60vh;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-height: 50vh;
			}
		}
	}
}

@keyframes animate-swipe-hand {
	0% {
		transform: translate(20px);
	}
	100% {
		transform: translate(-20px);
	}
}

// ##################################################
// Cookie banner
// ##################################################

@import '../../../scss/import';

// Style
.cookie-banner {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding: 20px 0;
	z-index: $zindex_cookie_notice;
	color: var(--color-black);
	background-color: var(--color-white);
	font-size: 14px;
	line-height: 1.5;
	transition: opacity 0.5s ease, visibility 0s linear 0.5s;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 30px 0;
	}

	// If Cookie is set
	&.cookie-set {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.5s ease, visibility 0s linear;
	}

	// ##################################################

	// Width
	.cookie-width {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		max-width: 1440px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex-direction: column;
		}
	}

	// ##################################################

	// Text
	// &__text {}

	// ##################################################

	// Button
	&__button {
		margin-left: 50px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-left: 0;
			margin-top: 20px;
			width: 100%;
		}
	}

	// ##################################################

	.btn--cookie {
		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 100%;
		}
	}
}

//**********************************************************
// Game Module
//**********************************************************

@import '../../../scss/import';

.game-module {
	position: relative;

	// ##################################################

	&__item {
		display: none;

		&.is-active {
			display: block;
		}
	}
}

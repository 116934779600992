// **************************************************
// Timer
// **************************************************

@import '../../../scss/import';

.base-timer {
	position: relative;
	width: 172px;
	height: 172px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-sky-blue);

	@media (max-height: 800px) {
		width: 140px;
		height: 140px;
	}

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		width: 140px;
		height: 140px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		height: 104px;
		width: 104px;
	}

	// ##################################################

	&__label {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 42px;
		font-weight: 700;

		@media (max-height: 800px) {
			font-size: 30px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 25px;
		}
	}

	// ##################################################

	&__svg {
		transform: scaleX(-1);
		width: 100%;
		height: 100%;
	}

	// ##################################################

	&__circle {
		fill: none;
		stroke: none;
	}

	// ##################################################

	&__path-elapsed {
		stroke-width: 8px;
		stroke: transparent;

		@media (max-height: 800px) {
			stroke-width: 6px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			stroke-width: 6px;
		}
	}

	// ##################################################

	&__path-remaining {
		stroke-width: 8px;
		stroke-linecap: round;
		transform: rotate(90deg);
		transform-origin: center;
		transition: 1s linear all;
		fill-rule: nonzero;
		stroke: currentColor;

		@media (max-height: 800px) {
			stroke-width: 6px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			stroke-width: 6px;
		}
	}

	// ##################################################

	&__path-remaining.green {
		color: var(--color-sky-blue);
	}

	// ##################################################

	&__path-remaining.orange {
		color: orange;
	}

	// ##################################################

	&__path-remaining.red {
		color: red;
	}
}

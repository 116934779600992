//*******************************************************************************
// WEBFONTS : Camel Sans
//*******************************************************************************

@font-face {
	font-family: 'Camel Sans';
	src: url('../fonts/CamelSans-Bold.woff2') format('woff2'), url('../fonts/CamelSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Camel Sans';
	src: url('../fonts/CamelSans-Demi.woff2') format('woff2'), url('../fonts/CamelSans-Demi.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.site-footer {
	width: 100%;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-height: 84px;
	color: var(--color-black);
}

//**********************************************************
// Spot Differences
//**********************************************************

@import '../../../scss/import';

.spot-differences {
	text-transform: uppercase;

	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 60px 0;

		@media (max-height: 800px) {
			padding: 30px 0;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 30px 0;
		}

		// ##################################################

		&-text {
			font-size: 35px;
			font-weight: 700;
			margin-left: 115px;

			@media (max-height: 800px) {
				margin-left: 60px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				margin-left: 60px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 21px;
				margin-left: 42px;
			}
		}
	}

	// ##################################################

	&__body {
		flex: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	// ##################################################

	&__product {
		flex: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		// ##################################################

		&-inner {
			position: relative;
		}

		// ##################################################

		img {
			width: auto;
			max-height: 40vh;

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-height: 34vh;
			}
		}
	}

	// ##################################################

	&__message {
		font-size: 32px;
		padding: 10px 0;
		min-height: 12vh;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-height: 800px) {
			font-size: 26px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 26px;
			min-height: 1px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 23px;
		}
	}
}

//**********************************************************
// Start Screen
//**********************************************************

@import '../../../scss/import';

.start-screen {
	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__header {
		// padding-left: 38px;
		margin-bottom: 54px;

		// Small desktop and down
		@media #{$breakpoint-md-down} {
			margin-bottom: 30px;
		}
	}

	// ##################################################

	&__row {
		height: 100%;
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(2, 1fr);

		// Small desktop and down
		@media #{$breakpoint-lg-down} {
			grid-template-columns: 40% 1fr;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 1fr;
			grid-gap: 20px;
		}
	}

	// ##################################################

	&__col {
		display: flex;
		align-items: center;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			justify-content: center;
			flex-direction: column;
		}
	}

	// ##################################################

	&__title {
		font-size: 127px;
		line-height: 1;
		text-shadow: 0 5px 6px rgba(0, 0, 0, 0.68);
		color: var(--color-white);

		// Small desktop and down
		@media #{$breakpoint-md-down} {
			font-size: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 81px;
		}
	}

	// ##################################################

	&__sub-title {
		margin-top: 18px;
	}

	// ##################################################

	&__content {
		width: 100%;
		padding-left: 54px;
		margin: 40px 0 20px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin: 20px 0;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding-left: 0;
			width: 100%;
			max-width: 270px;
		}
	}

	// ##################################################

	&__button {
		margin-top: 30px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin-top: 20px;
		}
	}

	// ##################################################

	&__product {
		max-width: 234px;
		width: 100%;
		box-shadow: 0 10px 33px rgb(0 0 0 / 45%);
		position: absolute;
		left: 0;
		top: 0;

		// Small desktop and down
		@media #{$breakpoint-md-down} {
			max-width: 190px;
		}

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			max-width: 140px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			max-width: 86px;
		}

		// ##################################################

		&-1 {
			left: 50px;
			top: 166px;
			transform: rotate(-11deg);

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				left: 12%;
				top: 40%;
			}
		}

		// ##################################################

		&-2 {
			left: inherit;
			right: 30px;
			transform: rotate(15deg);
			top: -20px;

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				right: 10%;
				top: 0;
			}
		}

		// ##################################################

		&-list {
			max-width: 566px;
			width: 100%;
			height: 500px;
			margin: 0 auto;
			position: relative;

			// Small desktop and down
			@media #{$breakpoint-md-down} {
				max-width: 466px;
				height: 360px;
			}

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				max-width: 366px;
				height: 230px;
				margin-bottom: 60px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-width: 216px;
				height: 170px;
				margin-bottom: 40px;
			}

			img {
				width: 100%;
				height: auto;
				object-fit: contain;
			}
		}
	}
}

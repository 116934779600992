
// ##################################################
// Colors
// ##################################################
:root {
	--color-white:			#fff;
	--color-black:			#000;
	--color-blue:			#{$cgp_blue};
	--color-yellow:			#{$cgp_yellow};
	--color-yellow-dark:	#{$cgp_yellow_dark};
	--color-sky-blue:		#{$cgp_sky_blue};
	--color-sky-blue_dark:	#{$cgp_sky_blue_dark};
	--color-sky-blue-2:		#{$cgp_sky_blue_2};
	--color-validation:		#ff3b30;
}

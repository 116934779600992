// **************************************************
// Video Block
// **************************************************

@import '../../../scss/import';

.video-block {
	position: relative;
	width: 100%;
	max-width: 402px;
	height: 256px;
	color: var(--color-sky-blue);
	border: 10px solid currentColor;
	border-radius: 6px;
	font-weight: 700;
	cursor: pointer;
	background-color: var(--color-white);

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		height: 168px;
		max-width: 300px;
	}

	// ##################################################

	&__button {
		@extend %abs_pos_fullwidth_normal;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		z-index: 3;
		background-color: var(--color-white);
		transition: opacity 0.3s ease;

		.is-playing & {
			opacity: 0;
			pointer-events: none;
		}

		// ##################################################

		span,
		.icon {
			pointer-events: none;
			transition: opacity 0.3s ease;
		}

		// ##################################################

		.icon {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 3;
			opacity: 0;
			transform: translate(-50%, -50%);

			&::before {
				background-color: var(--color-sky-blue);
			}
		}

		// ##################################################

		&:hover {
			span {
				opacity: 0;
			}
			.icon {
				opacity: 1;
			}
		}
	}

	&__video {
		@extend %abs_pos_fullwidth_normal;
		background-color: var(--color-black);
		object-fit: cover;
	}
}

//**********************************************************
// Intro screen
//**********************************************************

@import '../../../scss/import';

.intro-screen {
	position: relative;
	height: calc(100vh - 84px);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: rgb(45, 161, 226);
	background: linear-gradient(
		145deg,
		rgba(45, 161, 226, 1) 0%,
		rgba(58, 166, 228, 1) 18%,
		rgba(127, 193, 236, 1) 27%,
		rgba(157, 205, 240, 1) 33%,
		rgba(183, 215, 243, 1) 39%,
		rgba(183, 215, 243, 1) 50%,
		rgba(148, 201, 239, 1) 60%,
		rgba(70, 171, 229, 1) 89%,
		rgba(45, 161, 226, 1) 100%
	);

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		text-align: center;
		padding: 14vh 0 60px;
		height: auto;
		min-height: calc(100vh - 84px);
	}

	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__row {
		height: 100%;
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(2, 1fr);

		// Small desktop and down
		@media #{$breakpoint-lg-down} {
			grid-template-columns: 40% 1fr;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 1fr;
			grid-gap: 20px;
		}
	}

	// ##################################################

	&__col {
		display: flex;
		align-items: center;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			justify-content: center;
			flex-direction: column;
		}
	}

	// ##################################################

	.title {
		// ##################################################

		&--h2 {
			margin-top: 14px;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				left: 50%;
				min-width: 250px;
				position: relative;
				transform: translate(-50%, 0);
			}
		}
	}

	// ##################################################

	&__content {
		padding-left: 54px;
		margin: 20px 0;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding-left: 0;
			width: 100%;
			max-width: 225px;
		}
	}

	// ##################################################

	&__input {
		max-width: 323px;
		margin: 44px 0 21px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			margin: 24px 0 14px;
		}

		// ##################################################

		&-field {
			border-radius: 18px;
			min-height: 70px;
			padding: 0 32px;
			background-color: var(--color-white);
			border: 0;
			font-size: 24px;
			width: 100%;

			// Small desktop and down
			@media #{$breakpoint-lg-down} {
				font-size: 16px;
				min-height: 50px;
				border-radius: 8px;
				padding: 0 22px;
			}
		}
	}

	// ##################################################

	&__button {
		margin-top: 45px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			margin-top: 22px;
		}
	}

	// ##################################################

	&__images {
		position: relative;
		height: 630px;
		margin-top: 8vh;
		padding-left: 18px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			height: 235px;
			padding-left: 0;
			display: block;
			margin: 0 auto;
			max-width: 170px;
		}

		// ##################################################

		&-camel {
			position: absolute;
			z-index: 3;
			left: 26%;
			top: 26%;
			animation: camel 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-width: 86px;
				animation: camel-mobile 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;
			}
		}

		// ##################################################

		&-grid {
			// Small desktop and down
			@media #{$breakpoint-lg-down} {
				max-width: 480px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-width: 170px;
			}

			// stylelint-disable-next-line
			.#{$class_page_loaded} & {
				animation: animate-grid 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;
			}
		}

		// ##################################################

		&-product {
			max-width: 182px;
			position: absolute;
			z-index: 4;
			box-shadow: 0 10px 33px rgba($cgp_black, 0.45);
			transition: all 0.3s cubic-bezier(0.61, -0.02, 0.53, 1.11);

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				max-width: 80px;
			}

			// ##################################################

			img {
				width: 100%;
				object-fit: contain;
			}

			// ##################################################

			&-1 {
				transform: rotate(-12deg);
				top: 55%;
				right: -17%;

				// Tablet P and down
				@media #{$breakpoint-sm-down} {
					transform: rotate(24deg);
					top: 27%;
					right: -34%;
				}

				// stylelint-disable-next-line
				.#{$class_page_loaded} & {
					animation: animate-product-1 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;

					// Tablet P and down
					@media #{$breakpoint-sm-down} {
						animation: animate-product-1-mobile 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;
					}
				}
			}

			// ##################################################

			&-2 {
				top: 2%;
				right: -18%;
				opacity: 1;
				transform: translate(-40vw, 100vh) rotate(-20deg);

				// Tablet P and down
				@media #{$breakpoint-sm-down} {
					top: 8%;
					right: initial;
					left: -30%;
					transform: translate(18vh, 40vh) rotate(27deg);
				}

				// stylelint-disable-next-line
				.#{$class_page_loaded} & {
					animation: animate-product-2 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;

					// Tablet P and down
					@media #{$breakpoint-sm-down} {
						animation: animate-product-2-mobile 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;
					}
				}
			}
		}
	}

	// ##################################################

	&__lets {
		position: absolute;
		right: 80px;
		bottom: 70px;
		padding-right: 94px;
		font-size: 41px;
		text-transform: uppercase;
		animation: animate-lets 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 28px;
			bottom: initial;
			right: initial;
			top: 6vh;
			left: 50%;
			transform: translate(-50%, 0);
			padding-right: 70px;
		}

		// ##################################################
		// Yellow bg

		&::after {
			content: '';
			width: 78px;
			height: 112px;
			position: absolute;
			top: 50%;
			right: 0;
			background-color: var(--color-yellow);
			transform: rotate(10deg) translate(0, -50%);

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				width: 55px;
				height: 80px;
			}
		}

		// ##################################################

		.icon {
			position: absolute;
			right: -10px;
			z-index: 3;
			top: 0;
			transform: rotate(8deg);

			// Tablet L and up
			@media #{$breakpoint-md-up} {
				opacity: 0;
				animation: animate-camel-icon 3s cubic-bezier(0.63, 0.11, 0.22, 1) forwards;
			}

			&::before {
				background-color: var(--color-sky-blue);
			}
		}
	}
}

@keyframes animate-product-2 {
	0% {
		opacity: 0;
		transform: translate(-40vw, 100vh) rotate(-20deg);
	}
	40% {
		opacity: 1;
		transform: translate(0, 0) rotate(-10deg);
	}
	70% {
		opacity: 1;
		transform: translate(0, 0) rotate(-10deg);
		max-width: 182px;
	}
	100% {
		transform: translate(-59px, 0%) rotate(15deg);
		max-width: 230px;
	}
}

@keyframes animate-product-2-mobile {
	0% {
		opacity: 0;
		transform: translate(18vh, 40vh) rotate(27deg);
	}
	40% {
		opacity: 1;
		transform: translate(0, 0) rotate(-15deg);
	}
	70% {
		opacity: 1;
		transform: translate(0, 0) rotate(-15deg);
		max-width: 80px;
	}
	100% {
		transform: translate(160%, -14%) rotate(15deg);
		max-width: 86px;
	}
}

@keyframes animate-product-1 {
	0% {
		transform: translate(0, 0) rotate(-12deg);
	}

	70% {
		transform: translate(0, 0) rotate(-12deg);
		max-width: 182px;
	}

	100% {
		transform: translate(-130%, -40%) rotate(-11deg);
		max-width: 236px;
	}
}

@keyframes animate-product-1-mobile {
	0% {
		transform: translate(0, 0) rotate(24deg);
	}

	70% {
		transform: translate(0, 0) rotate(24deg);
		max-width: 80px;
	}

	100% {
		transform: translate(-160%, 0) rotate(-12deg);
		max-width: 86px;
	}
}

@keyframes animate-grid {
	0% {
		opacity: 1;
	}

	70% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes camel {
	0% {
		transform: translate(0, 0);
		transform: scale(1);
		opacity: 1;
	}

	75% {
		transform: translate(0, 0);
		transform: scale(1);
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate(25vw, 30vh) scale(0.2);
	}
}

@keyframes camel-mobile {
	0% {
		opacity: 1;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes animate-camel-icon {
	0% {
		opacity: 0;
	}

	90% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes animate-lets {
	0% {
		opacity: 0;
	}

	70% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################

.bg {
	&-image {
		img {
			@extend %abs_pos_fullwidth;
			max-height: initial;
		}
	}
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--32 {
		@extend %fontsize32;
	}

	// ##################################################

	&--24 {
		@extend %fontsize24;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--bold {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 500;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

.container {
	max-width: 1200px;
	padding: 0 15px;
	width: 100%;
	display: block;
	margin: 0 auto;

	// ##################################################

	.container {
		max-width: 100%;
	}
}

.f-auto {
	display: flex;
	flex-direction: column;
	flex: auto;
}

.yellow-screen {
	position: relative;
	height: calc(100vh - 84px);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: rgb(254, 196, 0);
	background: linear-gradient(
		145deg,
		rgba(254, 196, 0, 1) 0%,
		rgba(254, 196, 0, 1) 8%,
		rgba(254, 237, 55, 1) 27%,
		rgba(254, 237, 55, 1) 35%,
		rgba(254, 237, 55, 1) 66%,
		rgba(254, 196, 0, 1) 90%,
		rgba(254, 196, 0, 1) 100%
	);

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		text-align: center;
		padding: 130px 0 30px;
		height: auto;
		min-height: calc(100vh - 84px);
	}
}

//**********************************************************
// Product Slider
//**********************************************************

@import '../../../scss/import';

.product-slider {
	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}
}

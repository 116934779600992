// **************************************************
// Lets Block
// **************************************************

@import '../../../scss/import';

.lets-block {
	position: absolute;
	right: 80px;
	bottom: 70px;
	padding-right: 94px;
	font-size: 41px;
	text-transform: uppercase;

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		font-size: 28px;
		padding-right: 70px;
		right: 40px;
		bottom: 50px;
	}

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		bottom: initial;
		right: initial;
		top: 6vh;
		left: 50%;
		transform: translate(-50%, 0);
	}

	// ##################################################
	// Yellow bg

	&::after {
		content: '';
		width: 78px;
		height: 112px;
		position: absolute;
		top: 50%;
		right: 0;
		background-color: var(--color-sky-blue);
		transform: rotate(10deg) translate(0, -50%);

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			width: 55px;
			height: 80px;
		}
	}

	// ##################################################

	.icon {
		position: absolute;
		right: -10px;
		z-index: 3;
		top: 0;
		transform: rotate(8deg);

		&::before {
			background-color: var(--color-yellow);
		}
	}
}

//**********************************************************
// Results Screen
//**********************************************************

@import '../../../scss/import';

.results-screen {
	@media (max-height: 800px) {
		font-size: 16px;
	}

	// ##################################################

	.container {
		position: relative;
		z-index: 3;
	}

	// ##################################################

	&__header {
		margin-bottom: 46px;
		padding-left: 64px;

		@media (max-height: 800px) {
			padding-left: 0;
			margin-bottom: 24px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding-left: 0;
		}
	}

	// ##################################################

	&__row {
		height: 100%;
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(2, 1fr);

		// Small desktop and down
		@media #{$breakpoint-lg-down} {
			grid-template-columns: 40% 1fr;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 1fr;
			grid-gap: 0;
			position: relative;
		}
	}

	// ##################################################

	&__col {
		display: flex;
		align-items: center;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			justify-content: center;
			flex-direction: column;
		}

		// ##################################################

		&-right {
			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				position: absolute;
				top: 0;
				height: 100%;
				z-index: 3;
				pointer-events: none;
				overflow: hidden;
				left: -15px;
				width: calc(100% + 30px);
			}
		}
	}

	// ##################################################

	&__body {
		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			opacity: 0;
			visibility: hidden;
			transition: visibility 0.3s ease, opacity 0.3s ease;

			.is-animated & {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	// ##################################################

	&__title {
		font-size: 100px;
		line-height: 1;
		text-shadow: 0 5px 6px rgba(0, 0, 0, 0.68);
		color: var(--color-white);

		@media (max-height: 800px) {
			font-size: 70px;
		}

		// Small desktop and down
		@media #{$breakpoint-md-down} {
			font-size: 70px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 58px;
		}
	}

	// ##################################################

	&__content {
		width: 100%;
		padding-left: 54px;
		margin: 40px 0 20px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin: 20px 0;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding-left: 0;
			width: 100%;
			max-width: 270px;
		}
	}

	// ##################################################

	&__button {
		margin-top: 4px;

		.btn {
			min-width: 270px;

			@media (max-height: 800px) {
				min-width: 230px;
				min-height: 60px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				min-width: 220px;
			}
		}
	}

	// ##################################################

	&__table {
		border: 2px solid var(--color-blue);
		text-align: center;
		border-radius: 6px;
		max-width: 270px;
		margin-bottom: 30px;
		padding-bottom: 8px;

		@media (max-height: 800px) {
			margin-bottom: 16px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			border-radius: 18px;
		}

		// ##################################################

		&-row {
			display: grid;
			grid-gap: 8px;
			grid-template-columns: repeat(3, 1fr);
		}

		&-col {
			padding: 3px 0;
		}

		&-head {
			padding: 12px 4px;
		}
	}

	// ##################################################

	&__lets-text {
		font-size: 40px;
	}

	// ##################################################

	&__product {
		max-width: 220px;
		box-shadow: 0 10px 33px rgb(0 0 0 / 45%);
		position: absolute;
		left: 0;
		top: 0;

		// Small desktop and down
		@media #{$breakpoint-md-down} {
			max-width: 190px;
		}

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			max-width: 140px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			max-width: 128px;
			transition: all 0.3s ease;

			.is-animated & {
				max-width: 106px;
			}
		}

		// ##################################################

		&-1 {
			left: -13%;
			top: 18%;
			transform: rotate(-13deg);

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				left: 12%;
				top: 40%;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				left: 14%;
				top: 43%;

				.is-animated & {
					left: -6%;
					top: 56%;
					transform: rotate(-23deg);
				}
			}
		}

		// ##################################################

		&-2 {
			left: inherit;
			right: 30%;
			transform: rotate(5deg);
			top: -24%;

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				right: 30%;
				top: -34%;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				right: 10%;
				top: 27%;
				transform: rotate(5deg);

				.is-animated & {
					opacity: 0;
				}
			}
		}

		&-3 {
			left: initial;
			right: 16%;
			transform: rotate(16deg);
			z-index: -1;
			top: 40%;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				right: 10%;
				top: initial;
				bottom: 40px;
				z-index: 3;

				.is-animated & {
					right: -70px;
					top: 63%;
					transform: rotate(29deg);
				}
			}
		}

		// ##################################################

		&-list {
			// Tablet L and up
			@media #{$breakpoint-md-up} {
				max-width: 566px;
				width: 100%;
				height: 400px;
				margin: 0 auto;
				position: relative;
			}

			@media (max-height: 800px) {
				max-width: 396px;
			}

			// Tablet L
			@media #{$breakpoint-md-only} {
				max-width: 500px;
				height: 230px;
				margin-bottom: 60px;
			}

			img {
				width: 100%;
				height: auto;
				object-fit: contain;
				max-height: 40vh;

				@media (max-height: 800px) {
					max-height: 32vh;
				}
			}
		}
	}
}

// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	display: flex;
	align-items: center;
	min-height: 22px;
	font-size: 17px;
	line-height: 1.29;
	user-select: none;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 11px;
	}

	& + .checkbox {
		margin-top: 12px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			margin-top: 0;
		}
	}

	// ##################################################

	&__label {
		display: inline-block;
		line-height: normal;
		position: relative;
		padding-left: 32px;
		cursor: pointer;
		margin: 0;
		transition: color 0.3s ease;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding-left: 24px;
		}

		// ##################################################

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 100%;
		}

		// ##################################################

		// Unchecked circle
		&::before {
			width: 20px;
			height: 20px;
			left: 0;
			top: 2px;
			background-color: var(--color-white);
			transition: box-shadow 0.3s ease;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				width: 14px;
				height: 14px;
				top: 1px;
			}
		}

		// ##################################################

		// Checked circle
		&::after {
			left: 5px;
			top: 7px;
			width: 10px;
			height: 10px;
			background-color: var(--color-sky-blue);
			opacity: 0;
			transform: scale(0.001);
			transition: transform 0.3s ease, opacity 0.3s ease;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				left: 4px;
				top: 4px;
				width: 7px;
				height: 7px;
			}
		}
	}

	// ##################################################

	a {
		border: 0;
		text-decoration: underline;
	}

	// ##################################################

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		// ##################################################

		&:checked + .checkbox__label {
			&::after {
				@include scale(1);
				opacity: 1;
			}
		}

		// ##################################################

		&:focus + .checkbox__label {
			&::before {
				box-shadow: 0 0 0 3px rgba(#00324e, 0.2);
			}
		}
	}
}

//**********************************************************
// Product Images
//**********************************************************

@import '../../../scss/import';

.product-images {
	max-width: 566px;
	width: 100%;
	height: 600px;
	margin: 0 auto;
	position: relative;

	&__item {
		max-width: 234px;
		width: 100%;
		box-shadow: 0 10px 33px rgb(0 0 0 / 45%);
		position: absolute;
		left: 0;
		top: 0;

		&--1 {
			left: inherit;
			right: 0;
			transform: rotate(15deg);
		}
	}

	img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}
}

// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	cursor: pointer;
	padding: 8px 30px;
	min-width: 244px;
	min-height: 70px;
	background-color: var(--color-yellow);
	color: var(--color-sky-blue);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	font-size: 32px;
	font-family: $font_primary;
	font-weight: 700;
	border-radius: 16px;
	transition: background-color 0.25s ease-in, box-shadow 0.25s ease-in, color 0.3s ease;

	@media (max-height:800px) {
		font-size: 26px;
	}

	// Small desktop and down
	@media #{$breakpoint-lg-down} {
		min-height: 48px;
		border-radius: 10px;
		font-size: 22px;
		min-width: 225px;
	}

	// Hover/Focus
	&:hover,
	&:focus {
		background-color: var(--color-yellow-dark);
	}

	// ##################################################

	// Button text
	.btn__text {
		display: block;
	}

	&--blue {
		background-color: var(--color-sky-blue);
		color: var(--color-yellow);

		&:hover,
		&:focus {
			background-color: var(--color-sky-blue_dark);
		}
	}
}
